import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFlask, faUsers} from "@fortawesome/free-solid-svg-icons";
import Aos from 'aos';
import 'aos/dist/aos.css';
import {Trans, withTranslation} from "react-i18next";

Aos.init();

class ThreeColumns extends Component {
    render() {
        const { t } = this.props;

        return (
            <div className='three-columns' id="simple_steps" data-aos="fade-zoom-in"
                 data-aos-easing="ease-in"
                 data-aos-delay="300"
                 data-aos-offset="0">
                <div className='wrapper'>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 person-presentation">
                            <div className="icon">
                                <FontAwesomeIcon icon={faUsers} size="3x" />
                            </div>

                            <h4 className='person'>MICHAŁ KRZYŻANIAK</h4>
                            <p className='title'>CEO<br /><Trans> {t("personOneTitle")} </Trans></p>
                            <p className='description mail'><Trans> {t("personOneDescription")} </Trans></p>
                            {/*<p className='mail'><i className="far fa-envelope"></i> michal.krzyzaniak@pm4sol.com</p>*/}
                        </div>
                        {/*
                        <div className="col-xs-12 col-sm-4 person-presentation">
                            <div className="icon">
                                <FontAwesomeIcon icon={faFileInvoiceDollar} size="3x" />
                            </div>
                            <h4 className='person'>MICHAŁ SKWIERCZYŃSKI</h4>
                            <p className='title'>CFO<br /><Trans> {t("personTwoTitle")} </Trans></p>
                            <p className='description mail'><Trans> {t("personTwoDescription")} </Trans></p>
                            <p className='mail'><i className="far fa-envelope"></i> michal.skwierczynski@pm4sol.com</p>
                        </div>
                        */}
                        <div className="col-xs-12 col-sm-6 person-presentation">
                            <div className="icon">
                                <FontAwesomeIcon icon={faFlask} size="3x" />
                            </div>
                            <h4 className='person'>BARTOSZ ZIÓŁKOWSKI</h4>
                            <p className='title'>CRO<br /><Trans> {t("personThreeTitle")} </Trans></p>
                            <p className='description'><Trans> {t("personThreeDescription")} </Trans></p>
                            {/*<p><i className="far fa-envelope" style={{'color': '#BF381F'}}></i> bartosz.ziolkowski@pm4sol.com</p>*/}
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default withTranslation("translations")(ThreeColumns);
