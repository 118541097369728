import React, {Component} from "react";
import ThreeColumns from "./ThreeColumns";
import Menu from "./Menu";
import Aos from 'aos';
import 'aos/dist/aos.css';
import {Trans, withTranslation} from "react-i18next";

Aos.init();

class Header extends Component {
    render() {
        const { t } = this.props;

        return [
            <div className="wrapper" data-aos="fade-zoom-in"
                 data-aos-easing="ease-in"
                 data-aos-delay="300"
                 data-aos-offset="0">

                <Menu />

                <div className='row'>
                    <div className='hidden-xs col-sm-1'></div>
                    <div className='col-xs-12 col-sm-10'>
                        <h4 className='welcome-text'><Trans> {t("welcomeText1")} </Trans></h4>
                    </div>
                    <div className='hidden-xs col-sm-1'></div>
                </div>

            </div>,

            <div className="motto" data-aos="fade-zoom-in"
                 data-aos-easing="ease-in"
                 data-aos-delay="300"
                 data-aos-offset="0">
                <div className="wrapper">
                    <div className="row center">
                        <div className="col-12">
                            <h4><Trans> {t("welcomeText2")} </Trans></h4>
                        </div>
                    </div>
                </div>
            </div>,

            <div>
                <ThreeColumns />
            </div>
        ];
    }
}

export default withTranslation("translations")(Header);