import {Link} from "react-scroll";
import React, {Component} from "react";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Translation from "./Translation";
import {Trans, withTranslation} from "react-i18next";

class Menu extends Component {
    render() {
        const { t } = this.props;

        return [
            <header className="header hidden-xs">
                <div id="Logo" className="logo logo--desktop">
                    <a href="/">
                        <img src={require('../asstes/indeks be1622.jpg')} alt='logo' />
                    </a>
                </div>
                <div className="flex1"></div>
                <ul className="navigation" key="navigation">
                    <li key="simple"><Link to="simple_steps" spy={true} smooth={true} duration={1000}><Trans> {t("menuSection1")} </Trans></Link></li>
                    <li key="more"><Link to="more" spy={true} smooth={true} duration={1000}><Trans> {t("menuSection2")} </Trans></Link></li>
                    <li key="about"><Link to="about_us" spy={true} smooth={true} duration={1000}><Trans> {t("menuSection3")} </Trans></Link></li>
                    <li key="end"><Link to="contact" spy={true} smooth={true} duration={1000}><Trans> {t("menuSection4")} </Trans></Link></li>
                </ul>
            </header>,
            <div className='mobile-section hidden-lg hidden-md hidden-sm hidden-xl hidden-xxl'>
                <button id="menu-trigger"><FontAwesomeIcon icon={faBars} className='simple-icon' /></button>
                <ul className="navigation" key="navigation">
                    <li key="simple"><Link to="simple_steps" spy={true} smooth={true} duration={1000}><Trans> {t("menuSection1")} </Trans></Link></li>
                    <li key="more"><Link to="more" spy={true} smooth={true} duration={1000}><Trans> {t("menuSection2")} </Trans></Link></li>
                    <li key="about"><Link to="about_us" spy={true} smooth={true} duration={1000}><Trans> {t("menuSection3")} </Trans></Link></li>
                    <li key="end"><Link to="contact" spy={true} smooth={true} duration={1000}><Trans> {t("menuSection4")} </Trans></Link></li>
                </ul>
            </div>,
            <div className='hidden-lg hidden-md hidden-sm hidden-xl hidden-xxl logo-position'>
                <div id="Logo" className="logo logo--mobile">
                    <img src={require('../asstes/indeks be1622.jpg')} alt='logo' />
                </div>
            </div>,

            <Translation />
        ]
    }
}

export default withTranslation("translations")(Menu);
