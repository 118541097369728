import React, {Component} from "react";
import {withTranslation} from "react-i18next";

class ContactForm extends Component {
    state = {
        name: '',
        email: '',
        message: '',
        emailStatus: '',
        feedbackInformation: [],
        errors: []
    }

    handleChange = input => e => {
        this.setState( {[input]: e.target.value})
    }

    submitForm = (e) => {
        e.preventDefault();

        if (this.validate()) {

            const {name, email, message} = this.state
            //console.log(this.state);

            //create a new XMLHTTPRequest
            let xhr = new XMLHttpRequest();

            //get a callback when the server responds
            xhr.addEventListener('load', () => {
                this.setState ({
                    emailStatus: xhr.responseText
                });

                //update the emailStatus with the response
                //console.log(xhr.responseText);
            });

            xhr.open('GET', '//pm4sol.com/sendemail/index.php?sendto=' + email +
                '&name=' + name +
                '&message=' + message);

            //console.log(xhr);

            xhr.send();

            // reset the fields
            this.setState({
                name: '',
                email: '',
                message: ''
            });

            e.preventDefault();
        }
    }

    validate() {
        const errors = [];
        const { t } = this.props;

        const one = <p>{t("error1")}</p>;
        const two = <p>{t("error2")}</p>;
        const three = <p>{t("error3")}</p>;

        if (this.state.name.length < 5) {
            errors.push(one);
        }
        if (this.state.email.length < 3 && this.state.email.indexOf("@") === -1) {
            errors.push(two)
        }
        if (this.state.message === "") {
            errors.push(three);
        }
        this.setState({errors});
        return errors.length === 0;
    }

    render() {
        const {name, email, message, emailStatus} = this.state;
        const { t } = this.props;

        const statusMailSuccess = <p>{t("statusMailSuccess")}</p>;
        const statusMailError = <p>{t("statusMailError")}</p>;
        const statusMailRequire = <p>{t("statusMailRequire")}</p>;
        const statusMailServer = <p>{t("statusMailServer")}</p>;

        const feedbackInformation = [];

        if (this.state.emailStatus === "Wiadomość została wysłana") {
            feedbackInformation.push(statusMailSuccess);
        } else if (this.state.emailStatus === "Wszystkie pola są wymagane") {
            feedbackInformation.push(statusMailRequire);
        } else if (this.state.emailStatus === "Nie możesz używać tego serwera") {
            feedbackInformation.push(statusMailServer);
        } else {
            feedbackInformation.push(statusMailError);
        }

        return (
           <form onSubmit={this.submitForm}>
               {emailStatus ? <ul className="feedback">{feedbackInformation.map((info, index) => <li key={index}>{info}</li>)}</ul> : null}

               <ul className="feedback">{this.state.errors.map((error, index) => <li className="errors"  key={index}>{error}</li>)}</ul>

               <label>
                   <input className="form-control" type='text' placeholder={t("contactFormName")} value={name} onChange={this.handleChange('name')}/>
               </label>
               <label>
                   <input className="form-control" type='text' placeholder={t("contactFormEmail")} value={email} onChange={this.handleChange('email')}/>
               </label>
               <label>
                   <textarea className="form-control" placeholder={t("contactFormMessage")} value={message} onChange={this.handleChange('message')}/>
               </label>
               <label>
                   <input className="form-control btn-primary" type='submit' value={t("contactFormButton")}/>
               </label>
           </form>
        )
    }
}

export default withTranslation("translations")(ContactForm);