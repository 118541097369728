import React, {Component} from "react";
import {faQuoteLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Aos from 'aos';
import 'aos/dist/aos.css';
import {withTranslation} from "react-i18next";

Aos.init();

class Blockquote extends Component {
    render() {
        const { t } = this.props;

        return (
            <div className='blockquote' data-aos="fade-zoom-in"
                 data-aos-easing="ease-in"
                 data-aos-delay="300"
                 data-aos-offset="0">
                <div className="wrapper">
                    <div className="row main-container">
                        <div className="col-xs-12 col-sm-6">
                            <div className='row'>
                                <div className="col-xs-12">
                                    <div>
                                        <FontAwesomeIcon icon={faQuoteLeft} />
                                    </div>
                                    <p className="text">{t("blockquote")}</p>
                                    <p className="author">DEE HOCK</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-6 image-blockquote">
                            <img src={require('../asstes/money-2724241_1920-2.jpg')} alt='success'/>
                        </div>

                    </div>

                </div>
            </div>

        );
    }
}

export default withTranslation("translations")(Blockquote);