import React, {Component} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faMapMarkedAlt} from '@fortawesome/free-solid-svg-icons';
import 'aos/dist/aos.css';
import ContactForm from "./ContactForm";
import {withTranslation} from "react-i18next";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";

class Footer extends Component {
    render() {
        const { t } = this.props;

        return [
            <div className="contact" id="contact" data-aos="fade-zoom-in"
                 data-aos-easing="ease-in"
                 data-aos-delay="300"
                 data-aos-offset="0">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-xs-12 contact-title">
                            <h4>{t("contact")}</h4>
                        </div>

                        <div className='hidden-xs col-sm-2'></div>

                        <div className="col-xs-12 col-sm-4">
                            <ContactForm />
                        </div>

                        <div className="col-xs-12 col-sm-4 contact-us right-side">
                            <div>
                                <div className='row contact-container detail'>
                                    <div className='col-xs-2'>
                                        <FontAwesomeIcon icon={faEnvelope} size="2x" className="icon" />
                                    </div>
                                    <div className='col-xs-10 text'>
                                        <h4>office@pm4sol.com</h4>
                                    </div>
                                </div>

                                <div className='row contact-container detail'>
                                    <div className='col-xs-2'>
                                        <FontAwesomeIcon icon={faMapMarkedAlt} size="2x" className="icon" />
                                    </div>
                                    <div className='col-xs-10 text'>
                                        <h4>PM4 Solutions Sp. z o.o.</h4>
                                        <p>Al. Kalifornijska 1</p>
                                        <p>55-020 Rzeplin</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='hidden-xs col-sm-2'></div>
                    </div>

                </div>
            </div>,
            <div className="row copyright">
                <div className="col-12">
                    <p>&#xA9; Teresa Ziółkowska 2025</p>
                </div>
            </div>
        ]
    }
}

export default withTranslation("translations")(Footer);