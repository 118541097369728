import React, {Component} from "react";
import Aos from 'aos';
import 'aos/dist/aos.css';
import {Trans, withTranslation} from "react-i18next";

Aos.init();

class AboutUs extends Component {
    render() {
        const { t } = this.props;

        return (
            <div className='about-us' id="more" data-aos="fade-zoom-in"
                 data-aos-easing="ease-in"
                 data-aos-delay="300"
                 data-aos-offset="0">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 image-about-us">
                            <img src={require('../asstes/lab-217043_1280-2.jpg')} alt='lab'/>
                        </div>
                        <div className="col-xs-12 col-sm-6 about-us-container">
                            <div className='row text'>
                                <div  className="col-xs-12">
                                    <h4 className="text-steps"><Trans>{t("menuSection2")}</Trans></h4>
                                </div>
                                <div className="col-xs-12">
                                    <p><Trans>{t("teamText1")}</Trans></p>
                                    <p><Trans>{t("teamText2")}</Trans></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default withTranslation("translations")(AboutUs);