import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import StyledRadio from "./RadioStyles";

class Translation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "pl"
        };
    }

    handleChange = event => {
        let newlang = event.target.value;
        this.setState(prevState => ({value: newlang}));
        this.props.i18n.changeLanguage(newlang);
    };

    render() {
        return (
            <div className="app-bar">
                <FormControl component="fieldset">
                    <RadioGroup row value={this.state.value} onChange={this.handleChange}>
                        <FormControlLabel value="en" control={<StyledRadio />} label="En"/>
                        <FormControlLabel value="pl" control={<StyledRadio />} label="Pl"/>
                    </RadioGroup>
                </FormControl>
            </div>
        );
    }
}

export default withTranslation("translations")(Translation);