import React, {Component} from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import Home from './components/Home';
import './scss/main.scss';

class App extends Component {
    render() {
        return (
            <Router>
                <div>
                    <Route path="/" exact component={Home} />
                </div>
            </Router>
        );
    }
}

export default App;
