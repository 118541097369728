import React, {Component} from "react";
import {
    faChartLine,
    faFlask,
    faFunnelDollar,
    faMicroscope
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Aos from 'aos';
import 'aos/dist/aos.css';
import {withTranslation} from "react-i18next";

Aos.init();

class HelpSteps extends Component {
    render() {
        const { t } = this.props;

        return (
            <div className="help-steps" id="about_us" data-aos="fade-zoom-in"
                 data-aos-easing="ease-in"
                 data-aos-delay="300"
                 data-aos-offset="0">
                <div className="wrapper">
                    <div className="row">
                        <div className="col-xs-12">
                            <h4 className="title-section">{t("help")}</h4>
                        </div>

                        <div className='col-xs-12'>
                            <div className="row help-container">
                                <div className="col-xs-6 col-sm-3">
                                    <FontAwesomeIcon icon={faChartLine} className='help-icon' />
                                    <div className='description'>
                                        <h4 className='mobile-text'>{t("helpDiscipline1")}</h4>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-3">
                                    <FontAwesomeIcon icon={faFunnelDollar} className='help-icon' />
                                    <div className='description'>
                                        <h4 className='mobile-text'>{t("helpDiscipline2")}</h4>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-3">
                                    <FontAwesomeIcon icon={faFlask} className='help-icon' />
                                    <div className='description'>
                                        <h4 className='mobile-text'>{t("helpDiscipline3")}</h4>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-3">
                                    <FontAwesomeIcon icon={faMicroscope} className='help-icon' />
                                    <div className='description'>
                                        <h4 className='mobile-text'>{t("helpDiscipline4")}</h4>
                                    </div>
                                </div>

                                {/*<div className="col-xs-12 col-sm-4">*/}
                                {/*    <FontAwesomeIcon icon={faMicroscope} className='help-icon' />*/}
                                {/*    <div className='description'>*/}
                                {/*        <p className='mobile-text'>Doradztwo biznesowe</p>*/}
                                {/*        /!*<hr />*!/*/}
                                {/*        /!*<p className="special-small">lorem ipsum<br />lorem ipsum</p>*!/*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="col-xs-6 col-sm-2">*/}
                                {/*</div>*/}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation("translations")(HelpSteps);