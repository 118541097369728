import React from "react";
import Header from "./Header"
import Footer from "./Footer";
import SimpleSteps from "./HelpSteps";
import AboutUs from "./AboutUs";
import Blockquote from "./Blockquote";
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";

const Home = () => (
    <>
        <Header />
        <AboutUs />
        <SimpleSteps />
        <Blockquote />
        <Footer />

        <div>
                <ScrollUpButton />
        </div>
    </>
);

export default Home;